import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { alias, object, serializable } from 'serializr';

export class LoginResponse {
  @serializable(alias('userInfo', object(PendingInformationUpdates)))
  private _pendingInformationUpdates: PendingInformationUpdates;

  @serializable(alias('user', object(OldUserEntity)))
  private _user: OldUserEntity;

  get pendingInformationUpdates(): PendingInformationUpdates {
    return this._pendingInformationUpdates;
  }

  get user(): OldUserEntity {
    return this._user;
  }

  /**
   * @param user
   */
  set user(user: OldUserEntity) {
    this._user = user;
  }
}
